<template>
  <div class="box-body">
    <ul class="timeline timeline-inverse" v-for="history_item in task_history">
      <li class="time-label">
        <span class="bg-blue">
          {{ history_item.created_date }}
        </span>
      </li>
      <li>
        <div class="timeline-item">
          <h3 class="timeline-header" style="word-break: break-all;"
              :style="'background:' + history_item.type.custom_color">
            <strong style="font-weight: 600;">
              {{ history_item.action_type }}
            </strong>
          </h3>
          <div class="timeline-body">
            <p class="mb-1">
              {{ history_item.details }}
            </p>

            <p class="font-small">
              <span class="pull-left">
                Тип: <strong>{{ history_item.type.name }}</strong>
              </span>
              <span class="pull-right">
                Автор:
                <span v-if="history_item.creator">
                  <strong>{{ history_item.creator.name }}</strong> (ID {{ history_item.creator.id }})
                </span>
                <span v-else>
                  <strong>Клиент</strong>
                </span>
              </span>
            </p>

            <br/>
          </div>
        </div>
      </li>
      <li>
        <i class="fa fa-clock-o bg-gray"></i>
      </li>
    </ul>
    <div class="panel panel-default" v-if="task_history.length === 0">
      <div class="panel-body text-center text-muted mt-5 mb-5">
        Нет элементов для отображения
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'task-history',

  props: {
    taskId: {
      type: [String, Number],
      required: true,
    },
  },

  data: () => ({
    task_history: [],
  }),

  async created() {
    try {
      const task_history_response = await API.apiClient.get(
          '/task/' + this.taskId + '/history'
      );

      this.task_history = task_history_response.data;
    } catch (error) {
      errorHandler(error);
    }
  },
}
</script>

<style scoped>

</style>